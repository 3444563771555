import React from "react";
import { reduxForm, Field } from "redux-form";
import { renderField } from "../../form";
import { connect } from "react-redux";
// mport * as actions from '../../store/actions/index';
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import moment from "moment";
import momentLocaliser from "react-widgets-moment";
import { ajoutDay } from "../../store/actions/dayoff";
import "bootstrap/dist/css/bootstrap.min.css";
import "../demande.css";
import { MenuItem } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { Form} from "react-bootstrap";
import "../Demandes/style.scss";
import { ToastContainer } from "react-toastify-redux";
import renderSelect from '../../renderSelect';

momentLocaliser(moment);

const renderDateTimePicker = ({
  input: { onChange, value, className },
  showTime,
}) => (
    <DateTimePicker
      onChange={onChange}
      format="DD MMM YYYY"
      time={showTime}
      className={className}
      value={!value ? null : new Date(value)}
    />
  );


const mapDispatchToProps = {
  ajoutDay,
};

class AjoutDayoff extends React.Component {
  onSubmit(values) {
    return this.props.ajoutDay(values);
  }

  render() {
    const { handleSubmit, submitting } = this.props;

    return (
      <div>
         <div>
          <ToastContainer />
        </div>
        <div className="text-center">
          <h1>Ajouter un jour férié</h1>
        </div>
        <div className="row justify-content-md-center mt-5">
          <div class="col col-lg-6 bordered-form py-4 px-4">
            <form
              className="form-new-entry"
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <Form.Group>
                    <Form.Label>Date</Form.Label>
                    <Field
                      name="date"
                      showTime={false}
                      className="col-xs-4"
                      component={renderDateTimePicker}
                    />
                  </Form.Group>
              <Form.Group>
                <Field
                  label="Durée"
                  name="duree"
                  className="form-control"
                  type="number"
                  component={renderField}
                />
              </Form.Group>
              <Form.Group>
                <Field
                  label="Motif"
                  name="motif"
                  className="form-control"
                  type="text"
                  rows="3"
                  component={renderField}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Bureau</Form.Label>
                <div>
                  <Field name="bureau" component={renderSelect}>
                    <MenuItem />
                    <MenuItem value="Tunis">
                      Tunis
                    </MenuItem>
                    <MenuItem value="Paris">
                      France
                    </MenuItem>
                  </Field>
                </div>
              </Form.Group>
              <div>
                <button
                  className="btn btn-dark btn-block btn-lg mt-5"
                  disabled={submitting}
                >
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: "reactWidgets" })(connect(null, mapDispatchToProps)(AjoutDayoff));
