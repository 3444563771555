import * as actionTypes from "./actions-types";
import axios from "../../instance";
import { toastr } from "react-redux-toastr";
/// for users fecthing

export const fetchUserSuccess = (users) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    payload: users,
  };
};

export const fectUserFail = (error) => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    error: error,
  };
};

export const fetchUserStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START,
  };
};

// axios get users link

export const fectUsers = (
  token,
  pageNb,
  itemsPerPage,
  searchName,
  roles,
  resourceTeam,
  pagination
) => {
  return (dispatch) => {
    dispatch(fetchUserStart());

    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(
        `/api/users?isActive=true&term=${searchName}&page=${
          pageNb + 1
        }&itemsPerPage=${itemsPerPage}&roles=${roles}&resourceTeam=${resourceTeam}&pagination=${pagination}`
      )
      .then((res) => {
        const users = res.data["hydra:member"];
        const payload = {
          users,
          count:
            res.data && res.data["hydra:totalItems"]
              ? res.data["hydra:totalItems"]
              : 0,
          loading: false,
        };

        dispatch(fetchUserSuccess(payload));
      })
      .catch((err) => {
        dispatch(fectUserFail(err));
      });
  };
};

//Get all users

export const getUsersSuccess = (users) => {
  return {
    type: actionTypes.GET_USERS_SUCCESS,
    payload: users,
  };
};
export const getUsersFail = (error) => {
  return {
    type: actionTypes.GET_USERS_FAIL,
    error: error,
  };
};
export const getUsers = (token) => {
  return (dispatch) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .get(`/api/users?isManager=true&pagination=false`)
      .then((res) => {
        const users = res.data["hydra:member"];
        dispatch(getUsersSuccess(users));
      })
      .catch((err) => {
        dispatch(getUsersFail(err));
      });
  };
};
//Update User

export const onUpdateUserSuccess = (user, users) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    user: user,
    users: users,
  };
};
export const onUpdateUserFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: error,
  };
};
export const onUpdateUser = (token, userId, newData, oldData) => {
  const updateData = {};
  if (newData.manager1ID)
   {updateData.manager = `/api/users/${newData.manager1ID}`;}
  if (newData.manager2ID)
    {updateData.manager2 = `/api/users/${newData.manager2ID}`;}
  return (dispatch) => {
    axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    axios
      .put(`/api/users/${userId}`, updateData)
      .then((res) => {
        dispatch(onUpdateUserSuccess(res.data, oldData));
        toastr.success("Succès", "L'utilisateur a été mis à jour avec succès");
      })
      .catch((err) => {
        dispatch(onUpdateUserFail(err));
      });
  };
};
