import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../store/actions/index';
import MaterialTable from 'material-table';
import { Container } from '@material-ui/core';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import moment from "moment";
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import Button from "@material-ui/core/Button";
import AddCircle from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";

class DemandeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      search: '',
    }

  }
  componentDidMount() {
    this.props.onFetchDems(this.props.token);
  }
  waitForUpdateDemande(token, idDemande, DataUpdt, data) {
    this.props.onSetDemande(token, idDemande, DataUpdt, data);
  }
  render() {

    const congesList = [
      {
        title: 'lesson',
        field: 'En Cours',
      },
      {
        title: 'tele',
        field: 'Télétravail',
      },

      {
        title: 'sick',
        field: 'Maladie',
      },
      {
        title: 'dep',
        field: 'Déplacement professionel',
      },
      {
        title: 'paid',
        field: 'Congés payés',
      },
      {
        title: 'parent',
        field: 'Congés maternel ou paternel',
      },
      {
        title: 'unpaid',
        field: 'Congés non payés',
      },
    ];
    const congesLookup = {};
    congesList.forEach(item => congesLookup[item.title] = item.field);

    const demandesTableData = [];
    for (const key in this.props.demandess) {
      demandesTableData.push({
        id: this.props.demandess[key].id,
        color: 'green', //<span class={ this.props.demandess[key].status === '0' ||
        // this.props.demandess[key].status === '2' ? 'circle_orange' :
        // ((this.props.demandess[key].status === '3'  ? 'circle_green'  : 'circle_red')) }></span>,
        //type_original: this.props.demandess[key].type,
        type: this.props.demandess[key].type,
        // : this.props.demandess[key].type === 'paid' ? 'Congé payé' : ((this.props.demandess[key].type === 'parent' ? 'Congé maternel ou paternel' :
        // (this.props.demandess[key].type === 'unpaid' ? 'Congé non payé' : (this.props.demandess[key].type === 'dep' ? 'Déplacement professionel' :
        // (this.props.demandess[key].type === 'sick' ? 'Maladie' : (this.props.demandess[key].type === 'tele' ? 'Télétravail' : 'En Cours')))))),
        status: this.props.demandess[key].status === '0' || this.props.demandess[key].status === '2' ? 'En Cours' :
          (this.props.demandess[key].status === '3' ? 'Validé' : 'Rejeté'),
        datedebut: moment(this.props.demandess[key].datedebut.split("T")[0]).format("DD-MM-YYYY"),
        datefin: moment(this.props.demandess[key].datefin.split("T")[0]).format("DD-MM-YYYY"),
      });
    }


    const columns = [
      // { title: '', field: 'color', editable: 'never' },
      { title: 'Type de Congé', field: 'type', lookup: congesLookup },
      { title: 'Status', field: 'status', editable: 'never' },
      {
        title: 'Date de début', field: 'datedebut', editComponent: props => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <DatePicker
              format="dd-MM-yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              InputProps={{
                style: {
                  fontSize: 13,
                }
              }}
            />
          </MuiPickersUtilsProvider>
        )
      },
      {
        title: 'Date de fin', field: 'datefin', editComponent: props => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}
            locale={props.dateTimePickerLocalization}>
            <DatePicker
              format="dd-MM-yyyy"
              value={props.value || null}
              onChange={props.onChange}
              clearable
              InputProps={{
                style: {
                  fontSize: 13,
                }
              }}
            />
          </MuiPickersUtilsProvider>
        )
      },

    ]

    const demss = (<MaterialTable
      title="Demandes List"
      columns={columns}
      data={demandesTableData}
      editable={{
        onRowUpdate: (newData, oldData) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = [...demandesTableData];
                const index = data.findIndex(x => x.id === oldData.id);
                data[index] = { ...newData };

                const updateBody = {
                  type: newData.type,
                  datedebut: newData.datedebut,
                  datefin: newData.datefin,
                }
                this.waitForUpdateDemande(this.props.token, newData.id, updateBody, data)
              }
              resolve()
            }, 1000)
          });

        }
      }}
      options={{
        grouping: false,
        draggable: false,
        actionsColumnIndex: -1,
        paging: false,
        search: false,
        pageSize: 10,
        pageSizeOptions: [10, 50, 100, 200],
        headerStyle: {
          position: 'sticky',
          top: 0,
          width: "auto",
          tableLayout: "auto"
        },
        maxBodyHeight: '430px',
        exportButton: false,
        exportAllData: true,
      }}
      icons={{
        Edit: BorderColorRoundedIcon,
      }}
      isLoading={this.props.loading}
    />);
    return (
      <Container>
        <div>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <center><h3>Liste des congés</h3></center>
        <div
          style={{ width: 1100, margin: "0 auto 20px", textAlign: "right" }}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<AddCircle />}
          >
            <Link
              className=""
              to={{
                pathname: "/validation",
              }}
            >
              Ajouter un congé
              <i className="far fa-user" />
            </Link>
          </Button>
        </div>
        {demss}
      </Container>
    )
  }
}
const mapStateToProps = state => {
  return {
    /*  devis: state.devi.devis,
     loading: state.devi.loading,
     updatedDevis: state.devi.devi, */
    demandess: state.listdemandes.listdemandes,
    token: state.auth.token,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onFetchDems: (token) => dispatch(actions.listDemandes(token)),
    onSetDemande: (token, id, commData, data2) => dispatch(actions.setDemande(token, id, commData, data2))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DemandeList)
