import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {reducer as toastrReducer} from 'react-redux-toastr'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// my local reducers
import authReducer from './store/reducers/reducerauth';
import userReducer from './store/reducers/reduceruser';
import demandeReducer from './store/reducers/reducerdemande';
import demande1Reducer from './store/reducers/reducerPro';

import demandeSubmitStart from './store/reducers/reducersubmit';
import {reducer as formReducer} from 'redux-form';
import * as Sentry from '@sentry/browser';
import {toastsReducer as toasts} from 'react-toastify-redux';

import listdemandeReducer from './store/reducers/reducerlistdemande';
import searchdemandeReducer from './store/reducers/reducersearch';
import reducerNewPwd from './store/reducers/reducerNewPwd';
import reducerdayoff from './store/reducers/reducerdayoff';

const sentryU = process.env.sentryURL

Sentry.init({dsn: sentryU});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  demande: demandeReducer,
  demande1: demande1Reducer,
  listdemandes:listdemandeReducer,
  searchdemandes:searchdemandeReducer,
  toastr: toastrReducer,
  submit :demandeSubmitStart,
  form : formReducer,
  toasts : toasts,
  password: reducerNewPwd,
  dayoffs : reducerdayoff,
});


const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));


const app = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App  />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
