import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    listdemandes: [],
    loading: false,
}

const fetchsearchDemandeStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const fetchsearchDemandeSuccess = (state, action) => {
    return updateObject(state, {
        listdemandes: action.listdemandes,
        loading: false,
    });
}

const fecthsearchDemandeFail = (state, action) => {
    return updateObject(state, { loading: false });
}

const reducersearch = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.FETCH_SEARCH_DEMANDES_START: return fetchsearchDemandeStart(state, action);
        case actionTypes.FETCH_SEARCH_DEMANDES_SUCCESS: return fetchsearchDemandeSuccess(state, action);
        case actionTypes.FETCH_SEARCH_DEMANDES_FAIL: return fecthsearchDemandeFail(state, action);
        default: return state;
    }
}

export default reducersearch;
