import * as actionTypes from '../actions/actions-types';

import { updateObject } from '../../shared/utility';

const initState = {
    demande: null,
    loading: false
}

const fetch1DemandeStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const fetch1DemandeSuccess = (state, action) => {
    return updateObject(state, {
        demande: action.demande,
        loading: false
    });
}

const fecth1DemandeFail = (state, action) => {
    return updateObject(state, { loading: false });
}


const reducerPro = (state = initState, action = {}) => {
    switch (action.type) {
        case actionTypes.FETCH_DEMANDE_START: return fetch1DemandeStart(state, action);
        case actionTypes.FETCH_DEMANDE_SUCCESS: return fetch1DemandeSuccess(state, action);
        case actionTypes.FETCH_DEMANDE_FAIL: return fecth1DemandeFail(state, action);
        default: return state;
    }
}

export default reducerPro;
