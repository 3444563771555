import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// Be sure to include styles at some point, probably during your bootstraping
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import AdminPanel from "../../containers/AdminPanel/AdminPanel";
import Logout from "../../containers/Auth/Logout/Logout";
import Users from "../../containers/Users/Users";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";

import AjoutForm from "../../containers/Demandes/AjoutForm";
import DemandeContainer from "../../containers/DemandeContainer";
import FullCalendarResource from "../../containers/Calendar/FullCalendarResource";
import "../../containers/demande.css";
import styled from "styled-components";
import DemandeList from "../../containers/Demandes/DemandeList";
import {
  DateRange,
  HowToReg,
  PowerOff,
  PlaylistAdd,
  People,
  Settings,
} from "@material-ui/icons";
import history from "../../history";
import Password from "../../containers/Auth/Password";
import ResetPassword from "../../containers/Auth/ResetPassword";
import Dayoff from "../../containers/Dayoff/Dayoff";
import AjoutDayoff from "../../containers/Dayoff/AjoutDayoff";

const Main = styled.main`
  position: relative;
  transition: all 0.15s;
  padding: 0 20px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;

class SideNavPage extends React.Component {
  componentDidMount() {
    this.props.onFetchDemandes(this.props.token);
  }
  state = {
    selected: "home",
    expanded: false,
  };

  onSelect = (selected) => {
    this.setState({ selected: selected });
  };
  onToggle = (expanded) => {
    this.setState({ expanded: expanded });
  };
  render() {
    const { expanded } = this.state;

    const StyledSideNav = { backgroundColor: "#808080" , position: 'fixed' };

    return (
      <Router history={history}>
        <Route
          render={({ location, routeHistory }) => (
            <React.Fragment>
              <SideNav
                style={StyledSideNav}
                onSelect={(selected) => {
                  const to = "/" + selected;
                  if (location.pathname !== to) {
                    routeHistory.push(to);
                  }
                }}
                onToggle={this.onToggle}
              >
                <SideNav.Toggle />
                <SideNav.Nav defaultSelected="home">
                  {/* <NavItem eventKey="adminpanel">
                        <NavIcon>
                            <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em' }} />
                        </NavIcon>
                        <NavText>
                            Home
                        </NavText>
                    </NavItem>
                    */}
                  {(localStorage.getItem("roles") === "ROLE_ADMIN") || (localStorage.getItem("firstname") === "Maxime" ) ? (
                    <NavItem eventKey="fullcalendar">
                      <NavIcon>
                        <DateRange></DateRange>
                      </NavIcon>
                      <NavText>Planning</NavText>
                    </NavItem>
                  ) : (
                    ""
                  )}

                  {localStorage.getItem("roles") === "ROLE_MANAGER" ? (
                    <NavItem eventKey="demandes" >
                      <NavIcon>
                        <HowToReg></HowToReg>
                      </NavIcon>
                      <NavText>Demandes</NavText>
                      {this.props.demandes.map((dem) => (
                        <NavItem eventKey={`demandes/${dem.id}`} key={dem.id}>
                          {" "}
                          <NavText>
                            {dem.user.firstname}  {dem.user.lastname}
                          </NavText>
                        </NavItem>
                      ))}
                    </NavItem>
                  ) : (
                    ""
                  )}
                  <NavItem eventKey="demandelist">
                    <NavIcon>
                      <PlaylistAdd></PlaylistAdd>
                    </NavIcon>
                    <NavText>Congés</NavText>
                  </NavItem>
                  {localStorage.getItem("roles") === "ROLE_ADMIN" ? (
                  <NavItem eventKey="Users">
                    <NavIcon>
                      <People></People>
                    </NavIcon>
                    <NavText>Utilisateurs</NavText>
                  </NavItem>
                   ) : (
                    ""
                  )}
                  {localStorage.getItem("roles") === "ROLE_ADMIN" ? (
                  <NavItem   eventKey="params">
                    <NavIcon>
                      <Settings></Settings>
                    </NavIcon>
                    <NavText>Paramètres</NavText>
                    <NavItem eventKey="params/dayoff">
                      <NavText>Jours Fériés</NavText>
                    </NavItem>
                  </NavItem>
                  ) : (
                    ""
                  )}
                  <NavItem className="absolute" eventKey="logout">
                    <NavIcon>
                      <PowerOff></PowerOff>
                    </NavIcon>
                    <NavText>Déconnexion</NavText>
                  </NavItem>
                </SideNav.Nav>
              </SideNav>
              <main>
                <Main expanded={expanded} className="my-4">
                  {/*<div style={{ marginLeft: '100px' }} > */}
                  <Route path="/adminpanel" component={AdminPanel} />
                  <Route
                    path="/fullcalendar"
                    exact
                    component={FullCalendarResource}
                  />
                  <Route
                    path="/demandes/:id"
                    exact
                    component={DemandeContainer}
                  />
                  <Route path="/demandes" exact component={""} />
                  <Route path="/demandelist" exact component={DemandeList} />
                  <Route path="/users" exact component={Users} />
                  <Route path="/logout" exact component={Logout} />
                  <Route path="/validation" exact component={AjoutForm} />
                  <Route path="/password" exact component={Password} />
                  <Route path="/reset" exact component={ResetPassword} />
                  <Route path="/params" exact component={""} />
                  <Route path="/params/dayoff" exact component={Dayoff} />
                  <Route path="/addayoff" exact component={AjoutDayoff} />
                  {/*  <Redirect to="/demandelist" /> */}
                  {/* </div> */}
                </Main>
              </main>
            </React.Fragment>
          )}
        />
      </Router>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    token: state.auth.token,
    demandes: state.demande.demandescalendar,

  };
};
const roles = localStorage.getItem("roles");
console.log("roléééééé: ", roles);
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDemandes: (token) => dispatch(actions.fetchDemandes(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SideNavPage);
