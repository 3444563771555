import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    listdemandes: [],
    loading: false,
}

const fetchlistDemandeStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const fetchlistDemandeSuccess = (state, action) => {
    return updateObject(state, {
        listdemandes: action.listdemandes,
        loading: false,
    });
}

const fecthlistDemandeFail = (state, action) => {
    return updateObject(state, { loading: false });
}

const reducerlistdemande = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.FETCH_LIST_DEMANDES_START: return fetchlistDemandeStart(state, action);
        case actionTypes.FETCH_LIST_DEMANDES_SUCCESS: return fetchlistDemandeSuccess(state, action);
        case actionTypes.FETCH_LIST_DEMANDES_FAIL: return fecthlistDemandeFail(state, action);
        default: return state;
    }
}

export default reducerlistdemande;
