import React from "react";
import { reduxForm, Field } from "redux-form";
import { renderField } from "../../form";
import { connect } from "react-redux";
// mport * as actions from '../../store/actions/index';
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import moment from "moment";
import momentLocaliser from "react-widgets-moment";
import { ajout } from "../../store/actions/demande";
import "bootstrap/dist/css/bootstrap.min.css";
import "../demande.css";
import { ToastContainer } from "react-toastify-redux";
import { MenuItem } from "@material-ui/core";
import "react-toastify/dist/ReactToastify.css";
import { Form, Row, Col } from "react-bootstrap";
import "./style.scss";
import renderSelect from '../../renderSelect';

momentLocaliser(moment);

const renderDateTimePicker = ({
  input: { onChange, value, className },
  showTime,
}) => (
    <DateTimePicker
      onChange={onChange}
      format="DD MMM YYYY"
      time={showTime}
      className={className}
      value={!value ? null : new Date(value)}
    />
  );


const mapDispatchToProps = {
  ajout,
};

class AjoutForm extends React.Component {
  onSubmit(values) {
    return this.props.ajout(values);
  }
  /* componentWillMount () {
    this.props.initialize({ type : this.props.type });
  }
*/

  render() {
    const { demande } = this.props;
    const { handleSubmit, submitting } = this.props;
    console.log("validation demande: 00 ", demande);

    return (
      <div>
        <div>
          <ToastContainer />
        </div>
        <div className="text-center">
          <h1>Ajouter une nouvelle entrée</h1>
        </div>
        <div className="row justify-content-md-center mt-5">
          <div class="col col-lg-6 bordered-form py-4 px-4">
            <form
              className="form-new-entry"
              onSubmit={handleSubmit(this.onSubmit.bind(this))}
            >
              <h4 className="form-title">Informations générales</h4>
              <Form.Group>
                <Form.Label>Type de congé</Form.Label>
                <div>
                  <Field name="type" component={renderSelect}>
                    <MenuItem value="" />
                    <MenuItem value="paid">Congé payé</MenuItem>
                    <MenuItem value="sick">Maladie</MenuItem>
                    <MenuItem value="parent">
                      Congé maternel ou paternel
                    </MenuItem>
                    <MenuItem value="unpaid">Congé non payé</MenuItem>
                    <MenuItem value="tele">Télétravail</MenuItem>
                    <MenuItem value="dep">Déplacement professionnel</MenuItem>
                    <MenuItem value="lesson">En cours</MenuItem>
                  </Field>
                </div>
              </Form.Group>
              <Form.Group>
                <Field
                  label="Commentaire"
                  name="commentaire"
                  className="form-control"
                  type="textarea"
                  rows="3"
                  component={renderField}
                />
              </Form.Group>
              <h4 className="form-title mt-5">Durée du congé</h4>
              <Form.Group>
                <Form.Label>Durée du congé</Form.Label>
                <div>
                  <Field name="duree" component={renderSelect}>
                    <MenuItem />
                    <MenuItem value="Journées entières">
                      Journée entière
                    </MenuItem>
                    <MenuItem value="Demi-journée : Matinée">
                      Demi-journée : Matinée
                    </MenuItem>
                    <MenuItem value="Congé maternel ou paternel">
                      Demi-journée : Après-midi
                    </MenuItem>
                  </Field>
                </div>
              </Form.Group>
              <Row>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Date de Début</Form.Label>
                    <Field
                      name="datedebut"
                      showTime={false}
                      className="col-xs-4"
                      component={renderDateTimePicker}
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group>
                    <Form.Label>Date de Fin</Form.Label>
                    <Field
                      name="datefin"
                      showTime={false}
                      className="col-xs-4"
                      component={renderDateTimePicker}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <button
                  className="btn btn-dark btn-block btn-lg mt-5"
                  disabled={submitting}
                >
                  Envoyer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: "reactWidgets" })(connect(null, mapDispatchToProps)(AjoutForm));


