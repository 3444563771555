import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import '../../containers/demande.css';


export default function Lista(props) {


    return (

        <TableRow key={props.key}>
            <TableCell >{ props.date }</TableCell>
            <TableCell align="center">{ props.duree}</TableCell>
            <TableCell align="right">{props.motif}</TableCell>
        </TableRow>


    )
}
