import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import "../demande.css";
import MaterialTable from "material-table";
import TablePagination from "@material-ui/core/TablePagination";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import BackspaceIcon from "@material-ui/icons/Backspace";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";

class Users extends Component {
  state = {
    page: 0,
    itemsPerPage: 50,
    search: "",
    manager: null,
    manager2: null,
  };

  componentDidMount() {
    this.props.getUsers(this.props.token);
    this.props.onFetchUsers(
      this.props.token,
      this.state.page,
      this.state.itemsPerPage,
      this.state.search,
      "",
      "",
      true
    );
  }

  onPageChangeHandler = (event, newPage) => {
    this.setState({ page: newPage });
    this.props.onFetchUsers(
      this.props.token,
      newPage,
      this.state.itemsPerPage,
      this.state.search,
      "",
      "",
      true
    );
  };
  onRowsPerPageHandler = (pageSize) => {
    console.log(`page  ${pageSize}`);
    if (this.state.page !== 0) {
      this.setState({ page: 0 });
    }
    this.setState({ itemsPerPage: pageSize });
    this.props.onFetchUsers(
      this.props.token,
      0,
      pageSize,
      this.state.search,
      "",
      "",
      true
    );
  };
  onSearchHandler = (searchText) => {
    if (this.state.page !== 0) {
      this.setState({ page: 0 });
    }

    this.setState({ search: searchText });
    this.props.onFetchUsers(
      this.props.token,
      0,
      this.state.itemsPerPage,
      searchText,
      "",
      "",
      true
    );
  };

  waitForUpdateUser(token, idUser, newData, oldData) {
    this.props.onUpdateUser(token, idUser, newData, oldData);
  }

  render() {
    let usersOptions = [];
    const usersList = [];
    this.props.allUsers.map((op) => {
      return (usersOptions = [
        ...usersOptions,
        { value: op.id, label: op.fullname },
      ]);
    });
    this.props.allUsers.map((op) => {
      return (usersList[op.id] = op.fullname);
    });
    const columns = [
      { title: "Prénom", field: "firstname", editable: "never" },
      { title: "Nom", field: "lastname", editable: "never" },
      { title: "Position", field: "equipe", editable: "never" },
      {
        title: "Manager 1",
        field: "manager1ID",
        lookup: usersList,
      },
      {
        title: "Manager 2",
        field: "manager2ID",
        lookup: usersList,
      },
    ];

    const usersTableData = [];
    for (const key in this.props.users.users) {
      usersTableData.push({
        ...this.props.users.users[key],
      });
    }
    const users = (
      <MaterialTable
        title=""
        columns={columns}
        data={usersTableData}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  const data = [...usersTableData];
                  const index = data.indexOf(oldData);
                  data[index] = { ...newData };
                  this.waitForUpdateUser(
                    this.props.token,
                    newData.id,
                    newData,
                    data
                  );
                }
                resolve();
              }, 1000);
            }),
        }}
        options={{
          actionsColumnIndex: -1,
          pageSizeOptions: [50, 100, 200],
          pageSize: this.state.itemsPerPage,

          headerStyle: {
            position: "sticky",
            top: 0,
            fontSize: "25px",
            width: "auto",
            tableLayout: "auto",
          },
          maxBodyHeight: "650px",
        }}
        icons={{
          Edit: BorderColorRoundedIcon,
          Search: YoutubeSearchedForIcon,
          ResetSearch: BackspaceIcon,
        }}
        isLoading={this.props.users.loading}
        onSearchChange={this.onSearchHandler}
        onChangeRowsPerPage={this.onRowsPerPageHandler}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              count={parseInt(this.props.users.count)}
              page={this.state.page}
              onChangePage={this.onPageChangeHandler}
            />
          ),
        }}
      />
    );
    return (
      <div>
        <div class="text-center my-4">
          <h1>Liste des utilisateurs</h1>
        </div>
        <div className="mt-5">{users}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.fetchecdUsers,
    allUsers: state.user.allUsers,
    loading: state.user.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUsers: (
      token,
      nbPage,
      itemsPerPage,
      searchTxt,
      roles,
      resourceTeam,
      pagination
    ) =>
      dispatch(
        actions.fectUsers(
          token,
          nbPage,
          itemsPerPage,
          searchTxt,
          roles,
          resourceTeam,
          pagination
        )
      ),
    getUsers: (token) => dispatch(actions.getUsers(token)),
    onUpdateUser: (token, userId, newData, oldData) =>
      dispatch(actions.onUpdateUser(token, userId, newData, oldData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
