import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import Spinner from '../../components/UI/Spinner/Spinner';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import image1 from '../../components/img/cosa.png';
import '../../index.css';



class Auth extends Component {


    state = {
        controls: {
            username: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            },
            password: {
                value: '',
                validation: {
                    required: true,
                    minLength: 5
                },
                valid: false,
                touched: false
            }
        }
    }

    componentDidMount() {
        if ((this.props.authRedirectPath !== '/') || (this.props.authRedirectPath !== '/password')) {
            this.props.onSetAuthRedirectPath();
        }
    }

    inputChangeHandler = (event) => {

        // capture event names and their correposnding values
        const eventValue = event.target.value;
        const inputName = event.target.name;

        const updatedElements = updateObject(this.state.controls, {
            [inputName]: updateObject(this.state.controls[inputName], {
                value: eventValue,
                valid: checkValidity(eventValue, this.state.controls[inputName].validation),
                touched: true
            })
        });

        // updatedElements to update state values
        this.setState({ controls: updatedElements });
    }

    // handling form submission
    submitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(
            this.state.controls.username.value,
            this.state.controls.password.value
        );
    }

    // disabled button property
    disableSubmitHandler = () => {
        return !(this.state.controls.username.valid && this.state.controls.password.valid);
    }


    render() {


        //  dispaly a form or a spinner
        let form = (
            <div>
                <h3>Connexion</h3>
                <div className="form-group">
                    <label>Adresse email</label>
                    <input name="username" type="email" className="form-control" placeholder="Enter email"
                        onChange={(event) => this.inputChangeHandler(event)} />
                    <div style={{ fontSize: 12, color: "red" }}>
                        {!this.state.controls.username.valid && this.state.controls.username.touched ? "invalid Email" : null}
                    </div>
                </div>

                <div className="form-group">
                    <label>Mot de passe</label>
                    <input name="password" type="password" className="form-control" placeholder="Enter password"
                        onChange={this.inputChangeHandler} />
                    <div style={{ fontSize: 12, color: "red" }}>
                        {!this.state.controls.password.valid && this.state.controls.password.touched ? "invalid Password" : null}
                    </div>
                </div>
            </div>

        );


        if (this.props.loading) {
            form = <Spinner />
        }

        // afer a successful login , redirect to home page
        let authRedirect = null;
        if (this.props.isAuthenticated) {


            //.log('redirection ' + this.props.authRedirectAuth);
            authRedirect = <Redirect to={this.props.authRedirectAuth} />
        }

        return (
            <div className="col-sm-4 container" >

                {authRedirect}
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={image1} class="d-block w-101" alt="..." />
                    </div>
                </div>
                <div class="welcome">
                    <h1><strong>CosaVostra RH</strong></h1>
                    <span>Made with <span role="img" aria-label="heart"> ❤️ </span> by CosaVostra</span>
                    <br />&nbsp;<br />
                    <p>CosaVostra RH un outil de gestion des ressources humaines de
                        <a href="https://www.cosavostra.com" target="">
                            CosaVostra
                        </a>
                        qui optimise les processus administratifs en centralisant les données des employés
                    </p>
                </div>
                <form onSubmit={this.submitHandler}>
                    {form}

                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">Se souvenir de moi</label>
                        </div>
                    </div>

                    <button disabled={this.disableSubmitHandler()} type="submit" className="btn btn-lg btn-primary" >Se connecter</button>
                    <p className="forgot-password text-right" >
                        <Link to='/password'>Mot de passe oublié ?</Link>
                    </p>
                </form>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectAuth: state.auth.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/demandelist'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
