// Auth Action-Types
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

// users Action-Types
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

// not yet ready
export const SET_USERS_RESOURCE_ROLE = "SET_USERS_RESOURCE_ROLE";

// demandes Action-Types
export const FETCH_DEMANDES_START = "FETCH_DEMANDES_START";
export const FETCH_DEMANDES_SUCCESS = "FETCH_DEMANDES_SUCCESS";
export const FETCH_DEMANDES_FAIL = "FETCH_DEMANDES_FAIL";

// demandes Action-Types
export const FETCH_DEMANDESCALENDAR_START = "FETCH_DEMANDESCALENDAR_START";
export const FETCH_DEMANDESCALENDAR_SUCCESS = "FETCH_DEMANDESCALENDAR_SUCCESS";
export const FETCH_DEMANDESCALENDAR_FAIL = "FETCH_DEMANDESCALENDAR_FAIL";

// demandes Action-Types
export const FETCH_DEMANDE_START = "FETCH_DEMANDE_START";
export const FETCH_DEMANDE_SUCCESS = "FETCH_DEMANDE_SUCCESS";
export const FETCH_DEMANDE_FAIL = "FETCH_DEMANDE_FAIL";

//Status Action-types
export const STATUS_START = "STATUS_START";
export const STATUS_SUCCESS = "STATUS_SUCCESS";
export const STATUS_FAIL = "STATUS_FAIL";

//Status Action-types
export const DEMANDE_SUBMIT_START = "DEMANDE_SUBMIT_START";
export const DEMANDE_SUBMIT_SUCCESS = "DEMANDE_SUBMIT_SUCCESS";
export const DEMANDE_SUBMIT_FAIL = "DEMANDE_SUBMIT_FAIL";

// listdemandes Action-Types
export const FETCH_LIST_DEMANDES_START = "FETCH_LIST_DEMANDES_START";
export const FETCH_LIST_DEMANDES_SUCCESS = "FETCH_LIST_DEMANDES_SUCCESS";
export const FETCH_LIST_DEMANDES_FAIL = "FETCH_LIST_DEMANDES_FAIL";

// searchdemandes Action-Types
export const FETCH_SEARCH_DEMANDES_START = "FETCH_SEARCH_DEMANDES_START";
export const FETCH_SEARCH_DEMANDES_SUCCESS = "FETCH_SEARCH_DEMANDES_SUCCESS";
export const FETCH_SEARCH_DEMANDES_FAIL = "FETCH_SEARCH_DEMANDES_FAIL";

export const NEWPWD_START   = 'NEWPWD_START';
export const NEWPWD_SUCCESS = 'NEWPWD_SUCCESS';
export const NEWPWD_FAIL    = 'NEWPWD_FAIL';

export const FETCH_DAYOFF_START = "FETCH_DAYOFF_START";
export const FETCH_DAYOFF_SUCCESS = "FETCH_DAYOFF_SUCCESS";
export const FETCH_DAYOFF_FAIL = "FETCH_DAYOFF_FAIL";
export const GET_DAYOFF_SUCCESS = "GET_DAYOFF_SUCCESS";
export const GET_DAYOFF_FAIL = "GET_DAYOFF_FAIL";
export const DAYOFF_SUBMIT_SUCCESS = "DAYOFF_SUBMIT_SUCCESS";

export const SET_DEMANDES_START = 'SET_DEMANDES_START';
export const SET_DEMANDES_SUCCESS = 'SET_DEMANDES_SUCCESS';
export const SET_DEMANDES_FAIL = 'SET_DEMANDES_FAIL'
