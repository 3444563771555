import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import "../demande.css";
import Button from "@material-ui/core/Button";
import AddCircle from "@material-ui/icons/AddCircle";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import Lista from "../../components/Dayoff/Lista";

class Dayoff extends Component {

  componentDidMount() {
    this.props.getDayoffs(this.props.token);
  }

  render() {

    let dayoffsTable = null;
     dayoffsTable = this.props.allDayoffs.map((day) => {
        return (
          <Lista
            key={day.id}
            date={moment(day.date.split("T")[0]).format("DD-MM-YYYY")}
            duree={day.duree}
            motif={day.motif}
          />
        );
      });
    return (
      <div>
        <div class="text-center my-4">
          <h1>Configuration des jours fériés</h1>
        </div>
        <div className="mt-5">
        <div
            style={{ width: 1100, margin: "0 auto 20px", textAlign: "right" }}
          >
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddCircle />}
            >
              <Link
                className=""
                to={{
                  pathname: "/addayoff",
                }}
              >
                Ajouter un jour férié
                <i className="far fa-user" />
              </Link>
            </Button>
          </div>
          <TableContainer
        style={{ width: 1100, margin: "auto" }}
        component={Paper}
      >
        <Table style={{ width: 1100 }} aria-label="simple table">
          <TableHead class="oranga">
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell align="center">Nombre de jours</TableCell>
              <TableCell align="right">Motif</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{dayoffsTable}</TableBody>
        </Table>
      </TableContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dayoffs: state.dayoffs.fetchecdDayoffs,
    allDayoffs: state.dayoffs.allDayoffs,
    loading: state.dayoffs.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDayoffs: (token) => dispatch(actions.getDayoffs(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dayoff);
