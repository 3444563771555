import * as actionTypes from "../actions/actions-types";
import { updateObject } from "../../shared/utility";

const initialState = {
  fetchecdUsers: {
    users: [],
    count: "",
    lastPage: 1,
    currentPage: 0,
    filtering: false,
    loading: false,
  },
  allUsers: [],
};

const fetchUserStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchUserSuccess = (state, action) => {
  return {
    ...state,
    fetchecdUsers: {
      users: action.payload.users,
      currentPage: state.fetchecdUsers.currentPage + 1,
      count: action.payload.count,
      loading: action.payload.loading,
      filtering: false,
    },
  };
};

const fecthUserFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const getUsersFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const getUsersSuccess = (state, action) => {
  return {
    ...state,
    allUsers: action.payload,
  };
};

const updateUserFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const updateUserSuccess = (state, action) => {
  return {
    ...state,
    fetchecdUsers: {
      ...state.fetchecdUsers,
      users: action.users,
    },
  };
};

const reduceruser = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_USERS_START:
      return fetchUserStart(state, action);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fecthUserFail(state, action);
    case actionTypes.GET_USERS_SUCCESS:
      return getUsersSuccess(state, action);
    case actionTypes.GET_USERS_FAIL:
      return getUsersFail(state, action);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    default:
      return state;
  }
};

export default reduceruser;
