import React, { Component } from 'react';

import { renderField } from "../../form";

import { connect } from "react-redux";


import '../../index.css';
import { email } from "../../store/actions/auth";
import { Form } from "react-bootstrap";
import { reduxForm, Field } from "redux-form";
import { ToastContainer } from "react-toastify-redux";
import "../Demandes/style.scss";

const mapDispatchToProps = {
    email,
};

class Password extends Component {


    state = {
        controls: {
            username: {
                value: '',
                validation: {
                    required: true,
                    isEmail: true
                },
                valid: false,
                touched: false
            }
        }
    }

    onSubmit(values) {
        return this.props.email(values);
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div>
                <div>
                    <ToastContainer />
                </div>
                <div className="row justify-content-md-center mt-5">
                    <div class="col col-lg-6 bordered-form py-4 px-4">
                        <br></br> <br></br>
                        <center><h1>Bienvenue sur CosaVostra RH</h1></center>
                        <hr class="my-5"></hr>
                        <form
                            className="form-new-entry"
                            onSubmit={handleSubmit(this.onSubmit.bind(this))}
                        >
                            <h4 className="form-title">Reinitialiser votre mot de passe</h4>

                            <Form.Group>
                                <Field
                                    label="Adresse email "
                                    name="username"
                                    className="form-control"
                                    type="email"
                                    rows="3"
                                    component={renderField}
                                />
                            </Form.Group>



                            <div>
                                <button
                                    className="btn btn-dark btn-block btn-lg mt-5"
                                    disabled={submitting}
                                >
                                    Reinitialiser le mot de passe
                                </button>
                            </div>
                        </form>
                    </div>
                </div>


            </div>

        )
    }
}



export default reduxForm({ form: "reactWidgets" })(connect(null, mapDispatchToProps)(Password));
