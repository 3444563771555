import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

// loading is for the spinner loading ==> beautify the app a little
const initialState = {
    role: null,
    token: null,
    error: null,
    loading: false,
    authRedirectPath: '/'
}


// redirection action
const setAuthRedirectPath = (state, action) => {
    return updateObject(state, { authRedirectPath: action.path });
}


// authentication actions {start, success, fail, logout}
const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        token: action.token,
        error: null,
        loading: false,
        role: action.role
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    });
}

const authLogout = (state, action) => {
    return updateObject(state, {
        token: null
    });
}

// with return ~~~~ implmentation + break
const reducerauth = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        default: return state;
    }
}

export default reducerauth;
