import React, { Component } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import ResourceGrid from '@fullcalendar/resource-timeline';
import interactionPlugin from '@fullcalendar/interaction';
import * as actions from '../../store/actions/index';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/resource-timeline/main.css';
import '@fullcalendar/timeline/main.css';
import { transformEndDateDisplay } from '../../shared/utility.js';
import { connect } from 'react-redux';
import '../demande.css';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import moment from "moment";

const conges = [
    {
        value: 'attente',
        label: 'Demande en Attente',
    },
    {
        value: 'lesson',
        label: 'En Cours',
    },
    {
        value: 'tele',
        label: 'Télétravail',
    },
    {
        value: 'Ferié',
        label: 'Ferié',
    },
    {
        value: 'sick',
        label: 'Maladie',
    },
    {
        value: 'dep',
        label: 'Déplacement professionel',
    },
    {
        value: 'paid',
        label: 'Congés payés',
    },
    {
        value: 'parent',
        label: 'Congés maternel ou paternel',
    },
    {
        value: 'unpaid',
        label: 'Congés non payés',
    },
], equipe = [
    {
        value: 'Marketing',
        label: 'Marketing',
    },
    {
        value: 'Technique',
        label: 'Technique',
    },
    {
        value: 'Projet',
        label: 'Projet',
    },
    {
        value: 'Autre',
        label: 'Autre',
    },
], bureaux = [
    {
        value: 'Tunis',
        label: 'Tunis',
    },
    {
        value: 'Paris',
        label: 'Paris',
    },
    {
        value: 'Bordeaux',
        label: 'Bordeaux',
    },
    {
        value: 'Londres',
        label: 'Londres',
    },
];

class FullCalendarResource extends Component {

    state = {
        user: '',
        type: '',
        buro: '',
        ekip: '',
        name: localStorage.getItem("firstname"),
        role: localStorage.getItem("roles")
    }

    componentDidMount() {
        this.props.onFetchDemandes(this.props.token, this.getFilterURI());
        this.props.getDayoffs(this.props.token);
    }

    componentDidUpdate(prevProps, prevState) {

        if ((prevState.user !== this.state.user) || (prevState.type !== this.state.type) || (prevState.buro !== this.state.buro) || (prevState.ekip !== this.state.ekip)) {
            this.props.onFetchDemandes(this.props.token, this.getFilterURI());
        }
    }

    getFilterURI() {
        const filter = this.state;
        let filterURI = "";
        if (filter.user && filter.user > 0) {
            filterURI += "&user=" + filter.user;
        }
        if (filter.type) {
            filterURI += "&type=" + filter.type;
        }
        if (filter.buro) {
            filterURI += "&bureau=" + filter.buro;
        }
        if (filter.ekip) {
            filterURI += "&equipe=" + filter.ekip;
        }
        if (filter.name === 'Maxime' || filter.role === 'ROLE_ADMIN') {
            filterURI += "&planning=true";
        }
        return filterURI;
    }

    render() {
        const { user, type, buro, ekip } = this.state;
        const usersResourcesData = [], affectationsData = [], dayoffData = [], dayoffDataFr = [];
        let checkUser = null;
        for (const key in this.props.demandes) {
            checkUser = usersResourcesData.find((obj) => parseInt(obj.id) === parseInt(this.props.demandes[key].user.id))
            if (!checkUser) {
                usersResourcesData.push({
                    id: this.props.demandes[key].user.id,
                    title: this.props.demandes[key].user.firstname,
                });
            }
        }
        // Load Affectations Data
        let colorAffec = null;
        if (this.props.demandes) {
            for (const key in this.props.demandes) {
                if (((this.props.demandes[key].type === "paid") ||
                    (this.props.demandes[key].type === "parent") ||
                    (this.props.demandes[key].type === "unpaid")) &&
                    (this.props.demandes[key].status === '3')) {
                    colorAffec = '#dc3545';
                } else if ((this.props.demandes[key].type === "sick") && (this.props.demandes[key].status === '3')) {
                    colorAffec = '#28a745';
                }
                else if ((this.props.demandes[key].type === "dep") && (this.props.demandes[key].status === '3')) {
                    colorAffec = '#FF8C00 ';
                }
                else if ((this.props.demandes[key].type === "tele") && (this.props.demandes[key].status === '3')) {
                    colorAffec = '#007bff';
                }
                else if ((this.props.demandes[key].type === "lesson") && (this.props.demandes[key].status === '3')) {
                    colorAffec = '#8A2BE2';
                }
                else if (this.props.demandes[key].status !== '3') {
                    colorAffec = '#DA70D6';
                }


                affectationsData.push({
                    id: this.props.demandes[key].id,
                    //   title: this.props.demandes[key].type,
                    start: this.props.demandes[key].datedebut.split('+')[0],
                    end: transformEndDateDisplay(this.props.demandes[key].datefin.split('+')[0]),
                    resourceId: this.props.demandes[key].user.id,
                    color: colorAffec

                    //  isConfirmed: this.props.affectations[key].isConfirmed,

                    // color: colorAffec
                })

            }
        }

        const colorTN = '#ffc108';
        const colorFr = '#99ff66';
        let j = 0;
        function setDayoff(param1, param2, param3, param4) {

            while (j < usersResourcesData.length) {
                param1.push({
                    id: param2.id,
                    start: param2.date.split('+')[0],
                    end: transformEndDateDisplay(moment(param2.date.split('+')[0]).add(param2.duree - 1, 'day')),
                    resourceId: param3[j],
                    color: param4
                })
                j++;
            }
            j = 0;

        }
        const distinctYears = [...new Set(this.props.demandes.map(x => x.user.id))]


        if (this.props.allDayoffs) {
            for (const key in this.props.allDayoffs) {
                if (this.props.allDayoffs[key].bureau === 'Tunis') {
                    setDayoff(dayoffData, this.props.allDayoffs[key], distinctYears, colorTN);
                }
                else {
                    setDayoff(dayoffDataFr, this.props.allDayoffs[key], distinctYears, colorFr);
                }
            }
        }
        const handleChange = (event) => {
            this.setState({
                [event.target.name]: event.target.value,
            })
        };

        affectationsData.push(...dayoffData, ...dayoffDataFr);

        return (

            <div>
                <div class="text-center my-4">
                    <h1>Planning des congés</h1>
                </div>
                <div class="div-filtre">
                    <form >
                        <fieldset className="with-border" >
                            <legend>Filtres</legend>
                            <div class="flex-container">
                                <TextField
                                    id="nom"
                                    select
                                    label="Prénom et Nom"
                                    name="user"
                                    value={user}
                                    onChange={handleChange}
                                    style={{ width: '200px' }}
                                >
                                    {usersResourcesData.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.title}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="type"
                                    name="type"
                                    select
                                    label="Type de congé"
                                    value={type}
                                    onChange={handleChange}
                                    style={{ width: '200px' }}
                                >
                                    {conges.map((opt) => (
                                        <MenuItem key={opt.value} value={opt.value}>
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    id="bureau"
                                    select
                                    name="buro"
                                    label="Bureau"
                                    value={buro}
                                    onChange={handleChange}
                                    style={{ width: '200px' }}

                                >
                                    {bureaux.map((op) => (
                                        <MenuItem key={op.value} value={op.value}>
                                            {op.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    id="equipe"
                                    select
                                    name="ekip"
                                    label="Equipe"
                                    value={ekip}
                                    onChange={handleChange}
                                    style={{ width: '200px' }}

                                >
                                    {equipe.map((opti) => (
                                        <MenuItem key={opti.value} value={opti.value}>
                                            {opti.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="div60">


                    <div class="div-button">
                        <table class="table" >
                            <thead>
                                <tr className="tr">
                                    <th> <button type="button" className="btn btn-rose">Demande en attente</button></th>
                                    <th>  <button type="button" class="btn btn-violet">Cours</button></th>
                                    <th>  <button type="button" class="btn btn-bleu">Télétravail</button></th>
                                    <th>  <button type="button" class="btn btn-jaune">Férié TN</button></th>
                                    <th>  <button type="button" class="btn btn-cactus">Férié FR</button></th>
                                    <th>  <button type="button" class="btn btn-vert">Maladie</button></th>
                                    <th>  <button type="button" class="btn btn-oranger">Déplacement</button></th>
                                    <th>  <button type="button" class="btn btn-rouge">Congé</button></th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <br></br>

                    <div className="div70">
                        <FullCalendar
                            ref={this.props.calenadarRef}
                            //  plugin + views (license key)
                            defaultView="resourceTimelineWeek"
                            plugins={[dayGridPlugin, ResourceGrid, interactionPlugin]}
                            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                            locale='fr'
                            // header + styles + labelTexts
                            header={{
                                left: 'today,prev,next',
                                center: 'title',
                                right: 'resourceTimelineWeek,resourceTimelineMonth'
                            }}
                            buttonText={{
                                today: 'Aujourd\'hui',
                                month: 'Mois',
                                week: 'Semaine'
                            }}
                            height={300}
                            resourceLabelText='Equipe'
                            columnHeaderText="Day"
                            columnHeaderFormat={{
                                weekday: 'short',
                                month: 'numeric',
                                day: 'numeric',
                                omitCommas: true
                            }}
                            contentHeight='5px'
                            resourceAreaWidth={'15%'}
                            eventLimit={1}
                            eventBackgroundColor="blue"
                            slotLabelFormat={[{ weekday: 'long', month: 'numeric', day: 'numeric', omitCommas: true }]}
                            slotLabelInterval="24:00"
                            // Data: resources + events(affectations)
                            events={affectationsData}
                            resources={usersResourcesData}

                            // refecth on every prev/next click ~ disable fullcalendar cache
                            lazyFetching={false}

                            // time displaying controls
                            hiddenDays={[0, 6]}
                            slotDuration='12:00:00'
                            nowIndicator={true}
                            // minTime='08:00:00'
                            // maxTime='18:00:00'
                            //  scrollTime={9}
                            // businessHours={true}


                            // cells possible actions
                            // displayEventTime={true}
                            selectable={true}
                            editable={true}
                        // eventConstraint

                        />
                        {/*
                <ModalUI formData1={this.state.addAffectInitialData} onRef={this.onClick.bind(this)} />

                <ModalUIViewAffectation calenadarRef={this.props.calenadarRef} affectationData={this.state.viewAffectationData} onRef={this.onClickForView.bind(this)} />
*/}
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        // auth redcuer
        token: state.auth.token,
        // projects + users reducers
        users: state.user.fetchecdUsers,

        loading: state.user.loading,
        // affectation reducer
        demandes: state.demande.demandes,

        allDayoffs: state.dayoffs.allDayoffs,

    };
};
const mapDispatchToProps = dispatch => {
    return {
        //   onFetchProjects: (token, nbPage, itemsPerPage, searchTxt, pagination) => dispatch(actions.fetchProjects(token, nbPage, itemsPerPage, searchTxt, pagination)),
        // onFecthAffectations: (token, startRangeDate, endRangeDate) => dispatch(actions.fecthAffectations(token, startRangeDate, endRangeDate)),
        onFetchDemandes: (token, filterURI) => dispatch(actions.fetchDemandes(token, filterURI)),
        getDayoffs: (token) => dispatch(actions.getDayoffs(token))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FullCalendarResource);
