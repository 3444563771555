import * as actionTypes from './actions-types';
import axios from '../../instance';
import jwtDecode from 'jwt-decode';
import { toastr } from 'react-redux-toastr';



import { success} from 'react-toastify-redux';


const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token,role) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        role: role
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('roles');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};


export const checkAuthTimeOut = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

export const auth = (email, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            email: email,
            password: password
        }

        axios.post('/authentication_token', authData)
            .then(res => {
                console.log('backend url ', BACK_BASE_URL);
                if (res.status === 200) {
                    localStorage.setItem('token', res.data.token);
                    const decodedData = jwtDecode(res.data.token);
                    console.log("dataaaaaaaaaaaaaaaaaa",decodedData);
                    const expirationDate = new Date().getTime() + decodedData.exp * 1000;
                    localStorage.setItem('expirationDate', new Date(expirationDate));
                    localStorage.setItem('roles', decodedData.roles[0]);
                    localStorage.setItem('userid', decodedData.userid);
                    localStorage.setItem('firstname', decodedData.firstname);
                    localStorage.setItem('lastname', decodedData.lastname);
                    dispatch(authSuccess(res.data.token, decodedData.roles[0]));
                    dispatch(checkAuthTimeOut(decodedData.exp));
                }


            })
            .catch(err => {
                console.log(`login error  ${JSON.stringify(err)}`);

                if (err.response.status === 401) {
                    toastr.error('Failed to login', err.response.data.message);
                    dispatch(authFail(err.response.data));
                }
            });
    };
}


export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(!token){
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));

            if(expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeOut((expirationDate.getTime() - new Date().getTime()) / 1000));
            }
        }
    }
}


export const getRole = () => {

    const token = localStorage.getItem('token');

    const tokenPayload = jwtDecode(token);
    localStorage.setItem('roles', tokenPayload.roles);

    return localStorage.getItem('roles');
}

/*{"iat":1584914920,"exp":1584918520,"roles":["ROLE_ADMIN","ROLE_USER"],"username":"malek_2@gmail.com"}*/

export const email = (values) =>
{
    return dispatch => {
        const Data = {
            email: values.username,
        }
    axios.post(`${BACK_BASE_URL}/api/users/reset_password`, Data)
            .then(res => {dispatch(success('Nous vous avons envoyé un email'));}
            )
            .catch(error => { console.log('email  failed to send');
        });
    };
}
