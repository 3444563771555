import React from 'react';

export const renderField = ({input, label, className,type, meta: {error}}) => {

    return(
        <div className="form-group">
            {label !== null && label !== '' && <label> {label} </label>}
         {type !== 'textarea' &&    <input {...input} type={type}  />}
         {type === 'textarea' &&    <textarea {...input}  className={className} />}
        </div>
    );
};
