import * as actionTypes from './actions-types';
import axios from '../../instance';
import { toastr } from 'react-redux-toastr';
import {info} from 'react-toastify-redux';

const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;

export const statusStart = () => {
    return {
        type: actionTypes.STATUS_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.STATUS_SUCCESS,
    };
};

export const statusFail = (error) => {
    return {
        type: actionTypes.STATUS_FAIL,
        error: error
    };
};


export const stat1 = (demande) => {
    return dispatch => {
        dispatch(statusStart());
        const authData = {
            demande,
        }
        axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`};

        axios.post(`${BACK_BASE_URL}/api/demande/accept`, authData)
            .then(res => {
                dispatch(info('Vous avez validé la demande'));
                console.log("======response", res.data, demande)
                return res;
            })
            .catch(err => {
                console.log(`put error ${JSON.stringify(err)}`);

                if (err.response.status === 401) {
                    toastr.error('Failed to put', err.response.data.message);
                    dispatch(statusFail(err.response.data));
                }
            });
    }}

    export const stat2 = (demande) => {
        return dispatch => {
            dispatch(statusStart());
            const authData = {
                demande,
            }
            axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`};
            axios.post(`${BACK_BASE_URL}/api/demande/decline`, authData)
                .then(res => {
                    dispatch(info('Vous avez refusé la demande'));
                    console.log("=====decline=response", res.data, demande)
                    return res;
                })
                .catch(err => {
                    console.log(`put error ${JSON.stringify(err)}`);
                    if (err.response.status === 401) {
                        toastr.error('Failed to put', err.response.data.message);
                        dispatch(statusFail(err.response.data));
                    }
                });
            }
    }
