import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    demandes: [],
    loading: false,
    totalcount: 0,
    demandescalendar: [],
    loadingcalendar: false,
    totalcountcalendar: 0
}

const fetchDemandeStart = (state, action) => {
    return updateObject(state, { loading: true });
}

const fetchDemandeStartcalendar = (state, action) => {
    return updateObject(state, { loadingcalendar: true });
}

const fetchDemandeSuccess = (state, action) => {
    return updateObject(state, {
        demandes: action.demandes,
        loading: false,
        totalcount: action.total
    });
}
const fetchDemandeSuccesscalendar = (state, action) => {
    return updateObject(state, {
        demandescalendar: action.demandes,
        loadingcalendar: false,
        totalcountcalendar: action.total
    });
}

const fecthDemandeFail = (state, action) => {
    return updateObject(state, { loading: false });
}

const fecthDemandeFailcalendar = (state, action) => {
    return updateObject(state, { loadingcalendar: false });
}

const setDemandeStart = (state, action) => {
    return updateObject(state, {loading: true});
};

const setDemandeSuccess = (state, action) => {
    return updateObject(state, {
        demand: action.demand,
        deman: action.deman,
        loading: false
    });
};

const setDemandeFail = (state, action) => {
    return updateObject(state, { loading: false })
}


const reducerdemande = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionTypes.FETCH_DEMANDES_START: return fetchDemandeStart(state, action);
        case actionTypes.FETCH_DEMANDES_SUCCESS: return fetchDemandeSuccess(state, action);
        case actionTypes.FETCH_DEMANDES_FAIL: return fecthDemandeFail(state, action);
        case actionTypes.FETCH_DEMANDESCALENDAR_START: return fetchDemandeStartcalendar(state, action);
        case actionTypes.FETCH_DEMANDESCALENDAR_SUCCESS: return fetchDemandeSuccesscalendar(state, action);
        case actionTypes.FETCH_DEMANDESCALENDAR_FAIL: return fecthDemandeFailcalendar(state, action);
        case actionTypes.SET_DEMANDES_START: return setDemandeStart(state, action);
        case actionTypes.SET_DEMANDES_SUCCESS: return setDemandeSuccess(state, action);
        case actionTypes.SET_DEMANDES_FAIL: return setDemandeFail(state, action);

        default: return state;
    }
}

export default reducerdemande;
