import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
import { success } from 'react-toastify-redux';


const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;

const HYDRA_MEMBER_STR = 'hydra:member';
const URL_PALNNING_PARAM = "planning=true";

export const fetchDemandeSuccess = (demandes, total) => {
    return {
        type: actionTypes.FETCH_DEMANDES_SUCCESS,
        demandes: demandes,
        total: total
    };
};

export const fetchDemandeSuccesscalendar = (demandes, total) => {
    return {
        type: actionTypes.FETCH_DEMANDESCALENDAR_SUCCESS,
        demandes: demandes,
        total: total
    };
};


export const fetchDemandeFail = (error) => {
    return {
        type: actionTypes.FETCH_DEMANDES_FAIL,
        error: error
    };
};

export const fetchDemandeFailcalendar = (error) => {
    return {
        type: actionTypes.FETCH_DEMANDESCALENDAR_FAIL,
        error: error
    };
};

export const fetchDemandeStart = () => {
    return {
        type: actionTypes.FETCH_DEMANDES_START
    };
};

export const fetchDemandeStartcalendar = () => {
    return {
        type: actionTypes.FETCH_DEMANDESCALENDAR_START
    };
};

export const fetch1DemandeSuccess = (demande) => {
    console.log(`iiiiiiiiiiii ${demande}`);
    return {
        type: actionTypes.FETCH_DEMANDE_SUCCESS,
        demande: demande,
    };
};


export const fetch1DemandeFail = (error) => {
    return {
        type: actionTypes.FETCH_DEMANDE_FAIL,
        error: error
    };
};

export const fetch1DemandeStart = () => {
    return {
        type: actionTypes.FETCH_DEMANDE_START
    };
};


export const demandeSubmitSuccess = (demandeID) => {
    return {
        type: actionTypes.DEMANDE_SUBMIT_SUCCESS,
        demandesID: demandeID,
    };
};

export const fetchlistDemandeSuccess = (demandes) => {
    return {
        type: actionTypes.FETCH_LIST_DEMANDES_SUCCESS,
        listdemandes: demandes,
    };
};

export const fetchlistDemandeFail = (error) => {
    return {
        type: actionTypes.FETCH_LIST_DEMANDES_FAIL,
        error: error
    };
};

export const fetchlistDemandeStart = () => {
    return {
        type: actionTypes.FETCH_LIST_DEMANDES_START
    };
};


// axios

export const fetchDemandes = (token, urlParams) => {
    return dispatch => {
        if (urlParams && urlParams.includes(URL_PALNNING_PARAM)) {
            dispatch(fetchDemandeStart());
        } else {
            dispatch(fetchDemandeStartcalendar());
        }
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
        axios.get(`${BACK_BASE_URL}/api/demandes?${urlParams}`)
            .then(res => {
                const fecthedDemandes = [];
                for (const key in res.data[HYDRA_MEMBER_STR]) {
                    fecthedDemandes.push({
                        ...res.data[HYDRA_MEMBER_STR][key],
                        counter: res.data[HYDRA_MEMBER_STR][key].id
                    });
                }
                const total = res.data['hydra:totalItems'];
                if (urlParams && urlParams.includes(URL_PALNNING_PARAM)) {
                    dispatch(fetchDemandeSuccess(fecthedDemandes, total));
                } else {
                    dispatch(fetchDemandeSuccesscalendar(fecthedDemandes, total));
                }
            })
            .catch(err => {

                // in case the token is expired
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }
                if (urlParams && urlParams.includes(URL_PALNNING_PARAM)) {
                    dispatch(fetchDemandeFail(err));
                } else {
                    dispatch(fetchDemandeFailcalendar(err));
                }
            })
    }
}

export const ajout = (values) => {
    return dispatch => {
        const Data = {
            user: `/api/users/${localStorage.getItem('userid')}`,
            type: values.type,
            duree: values.duree,
            datedebut: values.datedebut,
            datefin: values.datefin,
            commentaire: values.commentaire,
        }
        axios.post(`${BACK_BASE_URL}/api/demandes`, Data)
            .then(res => {
                dispatch(demandeSubmitSuccess(res.id))
                dispatch(success('Demande envoyée'));
            }
            )
            .catch(error => {
                console.log('Submit failed');
            });
    };
}


export const fetchDemande = (token, id) => {
    return dispatch => {
        dispatch(fetch1DemandeStart());
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
        axios.get(`${BACK_BASE_URL}/api/demandes/${id}`)
            .then(res => {
                console.log('res demande: ', res.data);
                const fecthedDemande = {
                    demande: res.data
                };
                dispatch(fetch1DemandeSuccess(fecthedDemande))
            })
            .catch(err => {

                // in case the token is expired
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Sign in again');
                    dispatch(logout());
                }

                dispatch(fetchDemandeFail(err));
            })
    }
}

export const listDemandes = (token) => {
    return dispatch => {
        dispatch(fetchlistDemandeStart());
        axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };
        axios.get(`${BACK_BASE_URL}/api/mes-demandes`)
            .then(res => {

                const fetchedList = [];
                for (const key in res.data[HYDRA_MEMBER_STR]) {
                    fetchedList.push({
                        ...res.data[HYDRA_MEMBER_STR][key],
                    });
                }
                dispatch(fetchlistDemandeSuccess(fetchedList));
            })
            .catch(err => {
                dispatch(fetchlistDemandeFail(err));
            })
    }
}

export const setDemandeSuccess = (deman, demand) => {
    return {
        type: actionTypes.SET_DEMANDES_SUCCESS,
        deman: deman,
        demand: demand
    };
};
export const setDemandeFail = (error) => {
    return {
        type: actionTypes.SET_DEMANDES_FAIL,
        error: error
    };
};
export const setDemandeStart = () => {
    return {
        type: actionTypes.SET_DEMANDES_START
    };
};

export const setDemande = (token, demandeId, updateData, devisOutsideStore) => {

    return dispatch => {
        dispatch(setDemandeStart());
        const headerConfig = {
            headers: { Authorization: `Bearer ${token}` }
        };
        axios.put(`${BACK_BASE_URL}/api/demandes/${demandeId}`, updateData, headerConfig)
            .then(res => {
                if (res.status === 200) {
                    const updatedDevis = res.data;
                    dispatch(setDemandeSuccess(updatedDevis, devisOutsideStore));
                    toastr.success('Succès', 'Vos données sont bien mises à jour');
                }

            })
            .catch(err => {
                if (err.response.status === 401) {
                    toastr.warning(err.response.data.message, 'Please sign in again');
                    dispatch(logout());
                }
                dispatch(fetchDemandeFail(err));
            })
    }
}
