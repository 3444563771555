import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { connect } from "react-redux";
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';

import * as actions from './store/actions/index';

import Layout from './hoc/Layout/Layout';
import Password from './containers/Auth/Password';
import ResetPassword from "./containers/Auth/ResetPassword";


class App extends Component {
  // check token validity
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Switch>
        <Route path="/password"  component={Password} />
        <Route path="/reset"  component={ResetPassword} />
        <Route path="/" component={Auth} />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Layout>
          <Switch>
            {/* <Route path="/users" component={Users} /> */}
            <Route path="/logout" component={Logout} />
          </Switch>
        </Layout>
      );
    }

    return (
      <div className="main">
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
          closeOnToastrClick
        />

        {routes}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

// to add dispatch state to props
const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
