import React, { Component } from 'react';
import '../demande.css';
import image1 from '../../components/img/cosa.png';

class AdminPanel extends Component {


    render() {
        return (
            <div className="text-center">
 <div class="carousel-inner">
                <div class="carousel-item active">
            <img src={image1} class="d-block w-102" alt="..." />
              </div>
            </div>
             <h1> Bienvenue  {localStorage.getItem('firstname')}  {localStorage.getItem('lastname')} </h1>
            </div>
        )
    }
}


export default AdminPanel;
