import * as actionTypes from "../actions/actions-types";
import { updateObject } from "../../shared/utility";

const initialState = {
  fetchecdDayoffs: {
    dayoffs: [],
    loading: false,
  },
  allDayoffs: [],
};

const fetchDayoffStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchDayoffSuccess = (state, action) => {
  return {
    ...state,
    fetchecdDayoffs: {
      dayoffs: action.payload.dayoffs,
      loading: action.payload.loading,
    },
  };
};

const fecthDayoffFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const getDayoffFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const getDayoffSuccess = (state, action) => {
  return {
    ...state,
    allDayoffs: action.payload,
  };
};

const dayoffSubmitStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
}


const reducerdayoff = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_DAYOFF_START:
      return fetchDayoffStart(state, action);
    case actionTypes.FETCH_DAYOFF_SUCCESS:
      return fetchDayoffSuccess(state, action);
    case actionTypes.FETCH_DAYOFF_FAIL:
      return fecthDayoffFail(state, action);
    case actionTypes.GET_DAYOFF_SUCCESS:
      return getDayoffSuccess(state, action);
    case actionTypes.GET_DAYOFF_FAIL:
      return getDayoffFail(state, action);
    case actionTypes.DAYOFF_SUBMIT_SUCCESS:
      return dayoffSubmitStart(state, action);
    default:
      return state;
  }
};

export default reducerdayoff;
