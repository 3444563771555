import * as actionTypes from "./actions-types";
import axios from "../../instance";
import { info} from 'react-toastify-redux';
/// for dayoff fecthing

const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;


export const fetchDayoffSuccess = (dayoffs) => {
  return {
    type: actionTypes.FETCH_DAYOFF_SUCCESS,
    payload: dayoffs,
  };
};

export const fectDayoffFail = (error) => {
  return {
    type: actionTypes.FETCH_DAYOFF_FAIL,
    error: error,
  };
};

export const fetchDayoffStart = () => {
  return {
    type: actionTypes.FETCH_DAYOFF_START,
  };
};

export const getDayoffsSuccess = (dayoffs) => {
    return {
      type: actionTypes.GET_DAYOFF_SUCCESS,
      payload: dayoffs,
    };
  };
export const getDayoffsFail = (error) => {
    return {
      type: actionTypes.GET_DAYOFF_FAIL,
      error: error,
    };
  };

export const dayoffSubmitSuccess = () => {
    return {
        type: actionTypes.DAYOFF_SUBMIT_SUCCESS,
    };
};
export const getDayoffs = (token) => {
    console.log("===called", token)
    return (dispatch) => {
      axios
        .get(`/api/dayoffs`,{ headers: { Authorization: `Bearer ${token}` }})
        .then((res) => {
          const dayoffs = res.data["hydra:member"];
          dispatch(getDayoffsSuccess(dayoffs));
        })
        .catch((err) => {
            console.log('======error', err)
          dispatch(getDayoffsFail(err));
        });
    };
  };

  export const ajoutDay = (values) =>
{
    return dispatch => {
        const Data = {
            date: values.date,
            duree: parseInt(values.duree),
            motif: values.motif,
            bureau: values.bureau,
        }
    axios.post(`${BACK_BASE_URL}/api/dayoffs`, Data)
            .then(res => {dispatch(dayoffSubmitSuccess())
                dispatch(info('Jour férié ajouté'));}
            )
            .catch(error => { console.log('Submit failed');
        });
    };
}
