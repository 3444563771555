import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../store/actions/index';

import './demande.css';
import image1 from '../components/img/cosa.png';

import { ToastContainer } from 'react-toastify-redux';
import 'react-toastify/dist/ReactToastify.css'


const mapeStateToProps = state => {
    return {
        token: state.auth.token,
        demande: state.demande1.demande,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        // onFetchProjects: (token, nbPage, searchTxt) => dispatch(actions.fetchProjects(token, nbPage, searchTxt)),
        onFetchDemande: (token, id) => dispatch(actions.fetchDemande(token, id)),
        onStat: (demande) => dispatch(actions.stat1(demande)),
        onStat2: (demande) => dispatch(actions.stat2(demande))
    }
}

class DemandeContainer extends React.Component {
    state = {
        controls: {
            status: {
                value: 1
            },
        }
    }


    componentDidMount() {
        this.props.onFetchDemande(this.props.token, this.props.match.params.id);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.props.onFetchDemande(this.props.token, this.props.match.params.id);
        }
    }
    // handling form submission
    submitHandler = (event) => {
        event.preventDefault();
        this.props.onStat(
            this.state.controls.status,
            this.props.demande.id
        );
    }

    render() {
        const { demande } = this.props;
        console.log('demande containerrr : ', demande);
        const form = (
            <div>
            </div>
        );

        let demande1 = null;
        if (demande) {
            const Debut = demande.demande.datedebut.split('T')[0];
            const Fin = demande.demande.datefin.split('T')[0];

            const statusClass = demande.demande.status === '2' || demande.demande.status === '3' ? 'cercle' : 'cerclerouge';
            const validClass = (demande.demande.status === '3' ? 'cercle' : 'cerclerouge');
            const teleClass = (demande.demande.type === 'tele' ? 'Télétravail' : 'En cours');
            const maladClass = (demande.demande.type === 'sick' ? 'Maladie' : teleClass);
            const depClass = (demande.demande.type === 'dep' ? 'Déplacement professionel' : maladClass);
            const nonpaidClass = (demande.demande.type === 'unpaid' ? 'Congé non payé' : depClass);
            const parentClass = (demande.demande.type === 'parent' ? 'Congé maternel ou paternel' : nonpaidClass);


            demande1 = (
                <div>
                    <div>
                        <ToastContainer />
                    </div>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src={image1} class="d-block w-102" alt="..." />
                        </div>
                    </div>
                    <h1>Fiche de Congé</h1>
                    <table class="center" >
                        <tr>
                            <td class="text"> Nom Prénom</td>
                            <td class="text"><table width="100%" border="1" cellspacing="1" cellpadding="1"><tr><td>
                                {demande.demande.user.lastname}  {demande.demande.user.firstname}
                            </td></tr></table>
                            </td>
                        </tr>
                        <tr>
                            <td class="text">Type de congé</td>
                            <td class="text">
                                <table width="100%" border="1" cellspacing="1" cellpadding="1">
                                    <tr>
                                        <td>
                                            {demande.demande.type === 'paid' ? 'Congé payé' : parentClass}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <h2 class="w3-text-grey">Durée du Congé</h2>
                        <tr>
                            <td class="text">Durée du congé </td>
                            <td class="text"> <table width="100%" border="1" cellspacing="1" cellpadding="1"><tr><td>{demande.demande.duree}</td></tr></table></td>
                        </tr>
                        <tr>
                            <td class="text">A partir de </td>
                            <td class="text"> <table width="100%" border="1" cellspacing="1" cellpadding="1"><tr><td>{Debut}</td></tr></table></td>
                        </tr>
                        <tr>
                            <td class="text">Jusqu'au</td>
                            <td class="text"> <table width="100%" border="1" cellspacing="1" cellpadding="1"><tr><td>{Fin}</td></tr></table></td>
                        </tr>
                    </table>
                    <form onSubmit={this.submitHandler}  >
                        {form}
                        <div class="w3-section">
                            <div class="center div30" >
                                <table class="table" >
                                    <thead>
                                        <tr>
                                            <th> <button class=" btn w3-button w3-green" type="submit" onClick={() => this.props.onStat(demande.demande.id)} >Valider</button></th>
                                            <th> <button class=" btn w3-button w3-red" onClick={() => this.props.onStat2(demande.demande.id)}>Rejeter</button></th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </form>
                    <div class="center" id="container">
                        <div class="cercle " id="left">
                            {demande.demande.user.lastname} {demande.demande.user.firstname}
                        </div>
                        <div className={demande.demande.status === '0' ? 'cerclevide' : statusClass} id="center" style={{ display: "inline-block" }}>
                            {demande.demande.user.managers.manager1 ? demande.demande.user.managers.manager1.fullname : ""}
                        </div>
                        <div className={demande.demande.status === '0' || demande.demande.status === '2' ? 'cerclevide' : validClass} id="right">
                            {demande.demande.user.managers.manager2 ? demande.demande.user.managers.manager2.fullname : ""}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className="text-center">
                {demande1}
            </div>)
    }
}
export default connect(mapeStateToProps, mapDispatchToProps)(DemandeContainer);
