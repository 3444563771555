import React, { Component } from 'react';
import SideNavPage from '../../components/Navigation/SideNavPage';


class Layout extends Component {
    render() {
        return (
            <div>
                {/* <NavigationItems isAuth={this.props.isAuthen} /> */}

                <SideNavPage />
                <div style={{ marginLeft: '100px' }} >
                    <main>
                        {this.props.children}
                    </main>
                </div>
            </div>
        )
    }
}


export default Layout;

/*const mapStateToProps = state => {
    return {
        isAuthen: state.auth.token !== null
    }
}
export default  connect(mapStateToProps,null)(Layout)/*/
