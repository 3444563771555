import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    loading: false,
}

const demandeSubmitStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
}


const reducersubmit = (state = initialState, action = {}) => {
    if (action.type === actionTypes.DEMANDE_SUBMIT_START) {
         return demandeSubmitStart(state, action);}
         return state;
    }
export default reducersubmit;

