import { Select } from "@material-ui/core";
import React from 'react';

const renderSelect = ({
    input: { onChange, value, className },
    label,
    children,
    ...custom
  }) => (
      <Select className="form-item" value={value} onChange={onChange}>
        {children}
      </Select>
    );

export default renderSelect;
